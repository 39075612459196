import React from "react";
import { SEO } from "wmk-lib";
import { baseUrl, siteTitle } from "../vars/ThemeOptions";
import { graphql } from "gatsby";
import Layout from "../components/layout/LandingPages/HappyHour/HappyHourLayout";
import HappyHourPage from "../components/layout/LandingPages/HappyHour/HappyHourPage";
import slugify from "slugify";

export default ({ pageContext, data }) => {
  const { slug, campaign } = pageContext;
  const { lp, ty } = data;
  const { portalId } = data.global;
  const tySlug = slugify(ty.title.toLowerCase());
  return (
    <Layout>
      <SEO
        title={""}
        path={`/${campaign.slug}/${slug}`}
        slug={slug}
        //description={desc}
        ogImage={null}
        twitterImage={null}
        //twitterHandle={twitter}
        siteTitle={siteTitle}
        baseUrl={baseUrl}
      />
      {campaign.slug === "happy-hour" ? (
        <HappyHourPage
          slug={slug}
          campaign={campaign}
          pageData={lp}
          portalId={portalId}
          thanks={tySlug}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query lpQuery($slug: String!) {
    lp: contentfulCrmLandingPage(slug: { eq: $slug }) {
      ...CrmLandingPage
    }
    global: contentfulGlobal(title: { eq: "Hubspot Config" }) {
      portalId: headline
    }
    ty: contentfulCrmThankYou(title: { eq: "Happy Hour Thanks" }) {
      title
    }
  }
`;
