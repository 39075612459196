import React from "react";
import PropTypes from "prop-types";
import { colors as defaultColors } from "../../vars/palette";
import styled from "styled-components";

const Heading3 = styled.h3`
  color: ${({ colors }) => colors.hex("black")};
  ${({ center }) => center && "text-align: center;"};
`;

export const H3 = ({ children, colors, center }) => {
  return (
    <Heading3 colors={colors} center={center} style={{ fontSize: "2.25vh" }}>
      {children}
    </Heading3>
  );
};

H3.propTypes = {
  children: PropTypes.node.isRequired,
  colors: PropTypes.object,
};

H3.defaultProps = {
  colors: defaultColors,
};
