import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BubbleBackground from "../../../../testing/BubbleBackground/BubbleBackground";
import { H1, H2 } from "../../../../testing/typography";
import { baseUrl } from "../../../../vars/ThemeOptions";
import HappyHourCopy from "./HappyHourCopy";
import HappyHourForm from "./HappyHourForm";
import HappyHourImage from "./HappyHourImage";

const HappyHourPage = ({ pageData, portalId, campaign, thanks }) => {
  const [pageSize, setPageSize] = useState();

  const wrapRef = useRef();

  useEffect(() => {
    const page = wrapRef.current;
    const resize = () => {
      setPageSize(page.getBoundingClientRect());
    };
    resize();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [portalId]);

  const { title, h1, h2 } = pageData;
  return (
    <React.Fragment>
      <BubbleBackground pageSize={pageSize} />
      <Container ref={wrapRef}>
        <Row>
          <Col style={{ padding: "5vh 10vh 0 10vh" }}>
            <H1 center>{h1}</H1>
            <H2 center>{h2}</H2>
          </Col>
        </Row>
        <Row style={{ padding: "2.5vh 5vh 5vh 5vh" }}>
          <Col xs={12} sm={12} md={5}>
            <HappyHourCopy json={pageData.copy.json} />
          </Col>
          <Col
            xs={{ span: 12, order: "first" }}
            sm={{ span: 12, order: "first" }}
            md={{ span: 7, order: "last" }}
          >
            <Row className="flex-column">
              <Col>
                <HappyHourImage
                  src={pageData.image.file.url}
                  contentType={pageData.image.file.contentType}
                  alt={title}
                />
              </Col>
              <Col>
                <HappyHourForm
                  portalId={portalId}
                  formId={pageData.form.formId}
                  thanks={baseUrl + "/" + campaign.slug + "/" + thanks}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default HappyHourPage;
