import React from "react";

const HappyHourImage = ({ contentType, src, alt }) => {
  const isSvg = contentType && contentType.match("svg");
  return isSvg ? (
    <img
      src={src}
      alt={alt}
      style={{
        width: "50%",
        left: "40%",
        position: "relative",
        bottom: "-3vh",
        zIndex: 2,
      }}
    />
  ) : null;
};

export default HappyHourImage;
