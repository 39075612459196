import React from "react";
import HubForm from "react-hubspot-form";
import styled from "styled-components";
import { H3 } from "../../../../testing/typography/H3";
import { colors } from "../../../../vars/palette";
//import $ from "jquery";

const HubspotForm = styled.div`
  padding: 3vh;
  background: ${colors
    .getColor("secondary")
    .lighten(0.3)
    .hex()};
  border-radius: 1rem;
`;

const SmallPrint = styled.p`
  font-size: 1.85vh;
  color: ${colors.hex("nevada")};
  text-transform: uppercase;
`;

const HappyHourForm = ({ portalId, formId, thanks }) => {
  return (
    <HubspotForm>
      <H3>Register Today</H3>
      <SmallPrint>* Denotes Required</SmallPrint>
      <HubForm
        portalId={portalId}
        formId={formId}
        onSubmit={() => {
          window.location.href = thanks;
        }}
      />
      <SmallPrint>
        If you've received a birthday card from us in the past year, let us know
        the location and amount for your scratch off ticket!
      </SmallPrint>
    </HubspotForm>
  );
};

export default HappyHourForm;
